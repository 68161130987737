import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const FluidImage = ({ fluid, maxWidth, alt = ""}) => {
  return (
    <GatsbyImage
      image={fluid}
      style={{ maxWidth: maxWidth, width: "100%" }}
      alt={alt}
    />
  )
}

export default FluidImage
